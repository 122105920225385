export const AUTH_COMPLETED = 'AUTH_COMPLETED'
export const SET_AUTH_LOADING = 'SET_AUTH_LOADING'
export const SIGNED_OUT = 'SIGNED_OUT'
export const SET_ITEMS_ON_PAGE = 'SET_ITEMS_ON_PAGE'

export const SET_USERS = 'SET_USERS'
export const SET_USERS_LOADING = 'SET_USERS_LOADING'
export const SET_USER = 'SET_USER'
export const SET_USER_LOADING = 'SET_USER_LOADING'

export const SET_PRODUCTS = 'SET_PRODUCTS'
export const SET_PRODUCTS_LOADING = 'SET_PRODUCTS_LOADING'
export const SET_PRODUCTS_PAGE = 'SET_PRODUCTS_PAGE'
export const SET_PRODUCT = 'SET_PRODUCT'
export const SET_PRODUCT_LOADING = 'SET_PRODUCT_LOADING'

export const SET_PRODUCT_IMAGES = 'SET_PRODUCT_IMAGES'
export const SET_PRODUCT_IMAGES_LOADING = 'SET_PRODUCT_IMAGES_LOADING'

export const SET_MESSAGES = 'SET_MESSAGES'
export const SET_MESSAGES_LOADING = 'SET_MESSAGES_LOADING'
export const SET_MESSAGES_PAGE = 'SET_MESSAGES_PAGE'
export const SET_MESSAGE = 'SET_MESSAGE'
export const SET_MESSAGE_LOADING = 'SET_MESSAGE_LOADING'

export const SET_COMPANIES = 'SET_COMPANIES'
export const SET_COMPANIES_LOADING = 'SET_COMPANIES_LOADING'
export const SET_COMPANIES_PAGE = 'SET_COMPANIES_PAGE'
export const SET_COMPANY = 'SET_COMPANY'
export const SET_COMPANY_LOADING = 'SET_COMPANY_LOADING'

export const SET_CATEGORIES = 'SET_CATEGORIES'
export const SET_CATEGORIES_LOADING = 'SET_CATEGORIES_LOADING'
export const SET_CATEGORIES_PAGE = 'SET_CATEGORIES_PAGE'
export const SET_CATEGORY = 'SET_CATEGORY'
export const SET_CATEGORY_LOADING = 'SET_CATEGORY_LOADING'
export const SET_CATEGORY_ATTRS = 'SET_CATEGORY_ATTRS'
export const SET_CATEGORY_ATTRS_LOADING = 'SET_CATEGORY_ATTRS_LOADING'