import { RiHomeHeartFill } from "react-icons/ri";
import { BiMessageAltDetail, BiSolidPurchaseTag, BiCube, BiCategoryAlt } from "react-icons/bi";

export const version = '30.10.1' //версия приложения

export const BACKEND_URL_PREFIX = process.env.REACT_APP_URL
export const BACKEND_DOMAIN = process.env.REACT_APP_BACKEND_DOMAIN

export const itemsOnPage = 20

export const itemsCount = [10,15,20,25,30]

export const mainMenu = [
    {
        name: 'Главная',
        link: '/',
        icon: <RiHomeHeartFill/>,
        innerLinks: [
            {
                name: 'text',
                link: 'text'
            }
        ]
    },{
        name: 'Товары',
        link: '/products',
        icon: <BiSolidPurchaseTag/>,
        innerLinks: [
            {
                name: 'text',
                link: 'text'
            }
        ]
    },{
        name: 'Диалоги',
        link: '/messages',
        icon: <BiMessageAltDetail/>,
        innerLinks: [
            {
                name: 'text',
                link: 'text'
            }
        ]
    },{
        name: 'Поставщики',
        link: '/company',
        icon: <BiCube/>,
        innerLinks: [
            {
                name: 'text',
                link: 'text'
            }
        ]
    },{
        name: 'Категории',
        link: '/category',
        icon: <BiCategoryAlt/>,
        innerLinks: [
            {
                name: 'text',
                link: 'text'
            }
        ]
    }
]

